


































































































































































































































.organizations {
  &-body {
    margin-top: 30px;
    padding: 0 16px;

  }
}
